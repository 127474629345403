import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventCalendar from '../components/EventCalendar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import TournamentEvents from '../content/tournaments.json';
import ClinicEvents from '../content/events.json';
import EventCard, { formatEventHours} from '../components/EventCard';

//TODO make sure you get curremt year. //TODO standardize the event jsons to use event start day for multiday events?
const events = TournamentEvents.events.concat(ClinicEvents.events).map((event) => {
    
    return {...event, 
        start: new Date(`2022, ${event.start.date}, ${event.start.hours}:${event.start.minutes}`),
        end: new Date(`2022, ${event.end.date}, ${event.end.hours}:${event.end.minutes}`),
        allday: false,
        resource: false}
  })

export default function SchedulePage({data}){

    const [selectedEvent, selectEvent] = React.useState('');

    return (
        <Layout>
        <SEO title="Schedule" />
        <Grid container direction="column" alignItems="center" style={{textAlign: 'center'}}>
            <Grid item style={{padding: '2rem'}} xs={12}>
                <Typography variant="h3" style={{color: '#243E83'}}>Combined 2022 Event Schedule</Typography>
            </Grid>
            <Grid item style={{padding: '1rem', width: '90%'}} xs={12}>
                <EventCalendar events={events} height={650}
                    onSelectEvent={(eventObj, e) => {selectEvent(eventObj)}}/>
            </Grid>
            <Backdrop open={selectedEvent!=''} style={{zIndex: '4'}}>
                {selectedEvent !='' && <EventCard title={selectedEvent.title} variant="popover" closePopover={() => selectEvent('')}
                    cta="Interest"
                    time={{day: selectedEvent.day, hours: formatEventHours(
                        {hours: selectedEvent.start.getHours(), minutes: selectedEvent.start.getMinutes()}, 
                        {hours: selectedEvent.end.getHours(), minutes: selectedEvent.end.getMinutes()}
                    )}}
                    location={selectedEvent.location}
                    ageGroups={selectedEvent.ageGroups}
                    image={data[selectedEvent.imageKey].childImageSharp.gatsbyImageData} 
                    coaches={selectedEvent.coaches.map((coach) => {
                        return {image: data[coach.id].childImageSharp.gatsbyImageData, label: coach.name}
                    })}/>}
            </Backdrop>
        </Grid>
        </Layout>
    )
}
//TODO make this reusable quuery
export const query = graphql`
  query {
    passing: file(relativePath: {eq: "passing.jpg"}) {
        ...cardImage
    }
    digging: file(relativePath: {eq: "digging.jpg"}) {
        ...cardImage
    }
    hitting: file(relativePath: {eq: "hitting.jpg"}) {
        ...cardImage
    }
    serving: file(relativePath: {eq: "serving.jpg"}) {
        ...cardImage
    }
    blocker: file(relativePath: {eq: "blocker.jpg"}) {
        ...cardImage
    }
    kristen: file(relativePath: {eq: "kristen_falcinelli_profile.jpg"}) {
      ...cardImage
    },
    kelly: file(relativePath: {eq: "kelly_hughes_profile.jpg"}) {
      ...cardImage
    }
    enzo: file(relativePath: {eq: "enzo_lulushi_profile.jpg"}) {
      ...cardImage
    }
    jane: file(relativePath: {eq: "jane_feddersen_profile.jpg"}) {
      ...cardImage
    }
  }`